import { Box, Button, Typography } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import { currencyFormatter } from "helpers/currencyFormatter";

export default function PixLimitValidatorStatementsList({
  data,
  loading,
  loadingPaginated,
  loadMore,
  pagination,
}) {
  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  return (
    <BaasGrid style={{ marginTop: "16px" }}>
      <DinamicTable
        loading={loading}
        canDownloadCsv={data?.length > 0}
        data={data}
        displayActions={false}
        disableRowHover={true}
        tableSchema={[
          {
            header: "Movement Id",
            key: "movementId",
          },
          {
            header: "Amount",
            key: "amount",
            formatValue: (value) => `${currencyFormatter(value / 100)} BRL`,
          },
          {
            header: "Direction",
            key: "cashDirection",
          },
          {
            header: "Type",
            key: "financialType",
          },
          {
            header: "Debit Part.",
            key: "debitParty",
            formatValue: (value) =>
              `${value.bank || "N/A"} | ${value.branch || "N/A"} | ${
                value.account || "N/A"
              }`,
          },
          {
            header: "Credit Part.",
            key: "creditParty",
            formatValue: (value) =>
              `${value.bank || "N/A"} | ${value.branch || "N/A"} | ${
                value.account || "N/A"
              }`,
          },
        ]}
      />
      {data?.length ? (
        <Box width="100%" display="flex" justifyContent="space-between" mt={2}>
          <Typography color="GrayText" ml={1}>
            {data?.length || 0} of {pagination?.totalFound || 0} results
          </Typography>
          <Button
            variant="outlined"
            disabled={!Boolean(pagination?.hasMoreItems) || loadingPaginated}
            onClick={loadMore}
          >
            {loadingPaginated ? "Loading" : "Load more results"}
          </Button>
        </Box>
      ) : null}
    </BaasGrid>
  );
}
